import { type GridApi } from 'ag-grid-community';
import { type ListGridRow } from 'components/modules/modelling/lists/right-section/grid/types';
import { type UndoStackItem } from 'components/modules/modelling/lists/right-section/grid/undo-redo/types';
import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { type ID } from 'data';
import { type QueryTableColumnDataType } from 'data/big-query';
import { type CSVPreviewRowItem } from 'data/lists';
import {
  ListSortOrder,
  type List,
  type ListConfig,
  type ReconciliationConfig,
} from 'data/modelling/lists';
import { DEFAULT_DATE_FORMAT } from 'data/modelling/lists/constants';
import { AccessAction } from 'data/roles/permissions';
import { orderBy } from 'lodash';
import { queueMacroTask } from 'utils/queue-macro-task';
import { type StoreApi, createStore } from 'zustand';
import {
  cleanupConfigOnColumnUpdate,
  cleanupRowsOnColumnUpdate,
} from './utils/cleanup-config-rows-on-column-update';
import { sortedColumnValues } from './utils/sort-column-values';

type EmbedPlace = 'global-list' | 'report';

export interface ListsStoreApi {
  activeListId: number | null;
  gridApi?: GridApi;
  columnFilters?: Record<string, Set<string>>;
  searchQuery: string;
  columnWidthMap?: Record<string, number>;
  dataValidationPermissibleValuesMap?: Record<string, Set<string>>;

  id: number;
  name: string;
  displayName?: string;
  description: string;
  isDerivedList?: boolean;
  config: ListConfig;
  reportListColumnOrder?: string[];
  showReconAlert: boolean;
  setShowReconAlert: (val: boolean) => void;
  reconciliationConfig: ReconciliationConfig;
  rows: ListGridRow[];
  filteredRows: ListGridRow[];
  selectedRowIds: ID[];
  lastSelectedRow?: ID;
  undoStack: UndoStackItem[];
  redoStack: UndoStackItem[];
  isUndoOperationInProgress?: boolean;
  isRedoInProgress?: boolean;
  accessAction: AccessAction;
  isReadOnly: boolean;
  embedPlace: EmbedPlace;
  accessControlled: boolean;
  errors: { msg: string }[];
  maskedColumns: string[];
  bulkPasteRows?: CSVPreviewRowItem[];
  cellUpdateInProgress?: boolean;

  setActiveListId: (id: number) => void;
  setGridApi: (api: GridApi) => void;
  setColumnFilters: (filter?: Record<string, Set<string>>) => void;
  setSearchQuery: (searchQuery: string) => void;
  setSelectedRowIds: (rowIds: ID[]) => void;
  setLastSelectedRow: (rowId?: ID) => void;
  setIsReadOnly: (val: boolean) => void;
  setDescription: (val: string) => void;
  setDataValidationPermissibleValuesMap: (val: Record<string, Set<string>>) => void;
  setBulkPasteRows?: (rows?: CSVPreviewRowItem[]) => void;

  addNewRow: (row: ListGridRow) => void;
  bulkAddNewRows: (row: ListGridRow[]) => void;
  removeRows: (rowId: number[]) => void;
  updateRow: (row: ListGridRow) => void;
  updateRows: (rows: ListGridRow[]) => void;
  setFilteredRows: (rows: ListGridRow[]) => void;
  addNewColumn: (columnInfo: {
    columnName: string;
    type: QueryTableColumnDataType;
    dateFormat?: string;
  }) => void;
  updateColumn: (
    oldName: string,
    columnInfo: { columnName: string; type: QueryTableColumnDataType; dateFormat?: string },
  ) => void;
  removeColumn: (removeColumn: string) => void;
  updateColumnOrder: (columnOrder: string[], frozenColumns?: string[]) => void;
  sortRows: (columnName: string, sort: ListConfig['sortOrder']) => void;
  setColumnWidth: (
    name: string,
    width: number,
    cb: (o: {
      activeListId: number | null;
      updatedColumnWidthMap?: Record<string, number>;
    }) => void,
  ) => void;
  setConfig: (config: ListConfig) => void;

  pushToUndoRedoStack: (item: UndoStackItem) => void;
  resetUndoRedoStack: () => void;
  startUndoOperation: () => void;
  startRedoOperation: () => void;

  initializeStore: (options: {
    data: List & { reportListColumnOrder?: string[] };
    embedPlace: EmbedPlace;
  }) => void;
  initializeLocalData: (localData?: { columnWidthMap?: Record<string, number> }) => void;
}

export type ListsStore = ReturnType<typeof createListsStore>;

export const createListsStore = (): StoreApi<ListsStoreApi> => {
  return createStore<ListsStoreApi>()((set, get) => ({
    activeListId: null,

    id: 1,
    name: '',
    description: '',
    rows: [],
    filteredRows: [],
    config: {} as ListConfig,
    reconciliationConfig: {} as ReconciliationConfig,
    searchQuery: '',
    selectedRowIds: [],
    undoStack: [],
    redoStack: [],
    accessAction: AccessAction.RemoveAccess,
    isReadOnly: false,
    embedPlace: 'global-list',
    accessControlled: false,
    errors: [],
    maskedColumns: [],
    bulkPasteRows: undefined,
    cellUpdateInProgress: false,
    showReconAlert: false,

    setActiveListId: (id) => set({ activeListId: id }),
    setGridApi: (api) => set({ gridApi: api }),
    setColumnFilters: (filter) => set({ columnFilters: filter }),
    setSearchQuery: (query) => set({ searchQuery: query }),
    setSelectedRowIds: (rowIds) => set({ selectedRowIds: rowIds }),
    setLastSelectedRow: (rowId) => set({ lastSelectedRow: rowId }),
    setIsReadOnly: (val) => set({ isReadOnly: val }),
    setDescription: (description) => set({ description }),
    setDataValidationPermissibleValuesMap: (dataValidationPermissibleValuesMap) =>
      set({ dataValidationPermissibleValuesMap }),
    setBulkPasteRows: (bulkPasteRows?: CSVPreviewRowItem[]) => set({ bulkPasteRows }),

    addNewRow: (row) =>
      set({
        rows: [...get().rows, row],
        filteredRows: [...get().filteredRows, row],
      }),
    bulkAddNewRows: (rows) =>
      set({
        rows: [...get().rows, ...rows],
        filteredRows: [...get().filteredRows, ...rows],
      }),
    removeRows: (rowsIdsToRemove) => {
      const rowsToRemoveSet = new Set(rowsIdsToRemove);

      set({
        rows: get().rows.filter((row) => !rowsToRemoveSet.has(row.id)),
        filteredRows: get().filteredRows.filter((row) => !rowsToRemoveSet.has(row.id)),
      });

      queueMacroTask(() => get().gridApi?.refreshCells({ columns: ['row-index'] }));
    },
    updateRow: (updatedRow) => {
      set({
        rows: get().rows.map((row) => {
          if (row.id === updatedRow.id) {
            return updatedRow;
          }

          return row;
        }),
        filteredRows: get().filteredRows.map((row) => {
          if (row.id === updatedRow.id) {
            return updatedRow;
          }

          return row;
        }),
      });
    },
    updateRows: (updatedRows) => {
      set({ rows: updatedRows });
    },
    setFilteredRows: (updatedRows) => {
      set({ filteredRows: updatedRows });
    },
    addNewColumn: (columnInfo) => {
      const { config } = get();
      const { columnName, type, dateFormat } = columnInfo;
      const newConfig = {
        ...config,
        columnOrder: [...config.columnOrder, columnName],
        columnTypeMap: {
          ...config.columnTypeMap,
          [columnName]: type,
        },
        dateFormatMap: {
          ...(config.dateFormatMap || {}),
          [columnName]: dateFormat || DEFAULT_DATE_FORMAT,
        },
      };

      set({ config: newConfig });
    },

    updateColumn: (oldName, columnInfo) => {
      const { config, rows } = get();
      const { columnName } = columnInfo;

      const newConfig = cleanupConfigOnColumnUpdate({
        oldConfig: config,
        oldColumnName: oldName,
        updatedColumnInfo: columnInfo,
      });

      // will update filteredRows too
      const updatedRows = cleanupRowsOnColumnUpdate({
        oldRows: rows,
        oldColumnName: oldName,
        newColumnName: columnName,
      });

      set({ config: newConfig, rows: updatedRows });
    },

    removeColumn: (removeColumnName) => {
      const { config, rows } = get();

      const newDataValidation = { ...config.dataValidation };

      delete newDataValidation[removeColumnName];

      const newConfig = {
        ...config,
        columnOrder: config.columnOrder.filter((col) => col !== removeColumnName),
        frozenColumns: (config.frozenColumns || []).filter((col) => col !== removeColumnName),
        sortedColumn: config.sortedColumn === removeColumnName ? undefined : config.sortedColumn,
        sortOrder: config.sortedColumn === removeColumnName ? undefined : config.sortOrder,
        columnFormulae: (config.columnFormulae || []).filter(
          (columnFormula) => columnFormula.colName !== removeColumnName,
        ),
        dataValidation: newDataValidation,
      };

      // will update filteredRows too
      const updatedRows = rows.map((row) => {
        delete row.data[removeColumnName];

        return row;
      });

      set({ config: newConfig, rows: updatedRows });
    },

    updateColumnOrder: (columnOrder, frozenColumns) => {
      const { config } = get();

      const newConfig = {
        ...config,
        columnOrder,
        frozenColumns,
      };

      set({ config: newConfig });
    },

    setColumnWidth: (name, width, cb) => {
      const { activeListId, columnWidthMap } = get();

      const updatedColumnWidthMap = {
        ...(columnWidthMap || {}),
        [name]: width,
      };

      set({ columnWidthMap: updatedColumnWidthMap });

      cb({
        activeListId,
        updatedColumnWidthMap,
      });
    },

    sortRows: (columnName, sort) => {
      const { config, filteredRows } = get();
      let sortedRows = filteredRows;

      const newConfig = {
        ...config,
        sortedColumn: columnName,
        sortOrder: sort,
      };

      const columnType = config.columnTypeMap[columnName];
      const isDescending = sort === ListSortOrder.Desc;

      if (sort) {
        sortedRows = sortedColumnValues({
          rows: sortedRows,
          columnName,
          columnType,
          isDescending,
        });
      } else {
        sortedRows = orderBy(sortedRows, 'position');
      }

      set({ config: newConfig, filteredRows: sortedRows });
    },

    setConfig: (config) => {
      set({ config });
    },

    pushToUndoRedoStack: (item) => {
      const { undoStack, redoStack, isUndoOperationInProgress, isRedoInProgress } = get();

      if (isUndoOperationInProgress) {
        set({ redoStack: [...redoStack, item], isUndoOperationInProgress: false });
      } else if (isRedoInProgress) {
        set({
          undoStack: [...undoStack, item].slice(Math.max(undoStack.length - 20, 0)),
          isRedoInProgress: false,
        });
      } else {
        set({
          undoStack: [...undoStack, item].slice(Math.max(undoStack.length - 20, 0)),
          redoStack: [],
        });
      }
    },
    resetUndoRedoStack: () => set({ undoStack: [], redoStack: [] }),
    startUndoOperation: () => set({ isUndoOperationInProgress: true }),
    startRedoOperation: () => set({ isRedoInProgress: true }),
    setShowReconAlert: (val) => set({ showReconAlert: val }),

    initializeLocalData: async (localData) => {
      set({ columnWidthMap: localData?.columnWidthMap });
    },

    initializeStore: ({ data, embedPlace }) => {
      let sortedRows = data.rows;

      const { config } = data;
      const { sortOrder, sortedColumn } = config;

      if (sortOrder && sortedColumn) {
        const columnType = config.columnTypeMap[sortedColumn];

        sortedRows = sortedColumnValues({
          rows: sortedRows,
          columnName: sortedColumn,
          columnType,
          isDescending: sortOrder === ListSortOrder.Desc,
        });
      }
      const rows = sortedRows.map((r) => ({ ...r, backendId: r.id }));

      set({
        id: data.id,
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        isDerivedList: data.isDerivedList,
        rows,
        filteredRows: rows,
        config: data.config,
        reconciliationConfig: data.reconciliationConfig,
        accessAction: data.accessAction,
        embedPlace,
        isReadOnly:
          data.accessAction === AccessAction.ReadAccess ||
          embedPlace === 'report' ||
          isInVersionMode([VersionAccessModes.View, VersionAccessModes.SnapshotEdit]),
        accessControlled: data.accessControlled,
        errors: data.errors,
        maskedColumns: data.maskedColumns || [],
        reportListColumnOrder: data.reportListColumnOrder,
      });
    },
  }));
};
