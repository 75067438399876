import Bugsnag from '@bugsnag/js';
import { notification } from 'components/ui/atomic-components';
import { type ModelVersion, useModelVersionMutations } from 'data/modelling/model/versions';
import { ModelVersionApi } from 'data/modelling/model/versions/api';
import { type ModelTemplate } from 'data/templates';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useIsLoading, useIsLoadingPreview, useVersionActions } from 'store/editable-version';
import { StringParam, useQueryParams } from 'use-query-params';
import { defaultApiErrorHandler } from 'utils/error-handler';
import {
  VERSION_MODE_COOKIE_KEY,
  VERSION_ACCESS_COOKIE_KEY,
  VersionAccessModes,
  PREVIEW_MODEL_TEMPLATE,
  DEMO_MODEL_TEMPLATE,
} from '../constants';

export const useVersionMode = (): {
  isInModelPage: boolean;
  versionId?: string;
  accessMode?: VersionAccessModes;
  exitCurrentMode: (discard?: boolean) => Promise<void>;
  setVersionMode: (id: number, mode: VersionAccessModes, isPreview?: boolean) => Promise<void>;
  isLoading: boolean;
  isLoadingPreview: boolean;
} => {
  const isLoading = useIsLoading();
  const isLoadingPreview = useIsLoadingPreview();
  const { setIsLoading, setIsLoadingPreview } = useVersionActions();

  const { deleteModelVersion } = useModelVersionMutations();

  const [{ moduleName }] = useQueryParams({
    moduleName: StringParam,
  });

  const intl = useIntl();

  const location = useLocation();

  const versionId = Cookies.get(VERSION_MODE_COOKIE_KEY);
  const accessMode = Cookies.get(VERSION_ACCESS_COOKIE_KEY) as VersionAccessModes;

  const isInModelPage =
    ['/models', '/lists'].some((path) => location.pathname.startsWith(path)) ||
    (location.pathname.startsWith('/metrics') &&
      !!moduleName &&
      accessMode === VersionAccessModes.LiveVersionEdit);

  const setVersionMode = async (id: number, mode: VersionAccessModes, isPreview?: boolean) => {
    isPreview ? setIsLoadingPreview(true) : setIsLoading(true);

    Cookies.set(VERSION_MODE_COOKIE_KEY, `${id}`);
    Cookies.set(VERSION_ACCESS_COOKIE_KEY, mode);

    try {
      if (!isPreview) {
        await ModelVersionApi.exitVersionMode({ discard: true });
      }

      window.location.href = '/models';
    } catch {
      isPreview ? setIsLoadingPreview(false) : setIsLoading(false);

      Cookies.remove(VERSION_MODE_COOKIE_KEY);
      Cookies.remove(VERSION_ACCESS_COOKIE_KEY);

      Cookies.remove(PREVIEW_MODEL_TEMPLATE);
      Cookies.remove(DEMO_MODEL_TEMPLATE);

      notification.error({
        message: intl.formatMessage({
          id: 'model.versions.view.launching_version_error',
        }),
      });
    }
  };

  const exitCurrentMode = async (discard = true) => {
    try {
      await ModelVersionApi.exitVersionMode({ discard });
    } catch (err) {
      defaultApiErrorHandler(err);
      Bugsnag.notify('Version exit failed');
    }

    const demoTemplate = Cookies.get(DEMO_MODEL_TEMPLATE);

    if (demoTemplate && versionId) {
      await deleteModelVersion.mutateAsync({ id: Number(versionId) } as ModelVersion);
    }

    Cookies.remove(VERSION_MODE_COOKIE_KEY);
    Cookies.remove(VERSION_ACCESS_COOKIE_KEY);

    const previewModelTemplateCookieData = Cookies.get(PREVIEW_MODEL_TEMPLATE);
    const previewModelTemplate = previewModelTemplateCookieData
      ? (JSON.parse(previewModelTemplateCookieData) as ModelTemplate)
      : null;

    const previewOrDemoTemplateName =
      previewModelTemplate?.name || Cookies.get(DEMO_MODEL_TEMPLATE);

    Cookies.remove(PREVIEW_MODEL_TEMPLATE);
    Cookies.remove(DEMO_MODEL_TEMPLATE);

    if (previewOrDemoTemplateName) {
      window.location.href = `/models/model-templates/${previewOrDemoTemplateName}`;
    } else if (isInModelPage) {
      window.location.href = '/models';
    } else {
      window.location.reload();
    }
  };

  return {
    isInModelPage,
    versionId,
    accessMode,
    setVersionMode,
    exitCurrentMode,
    isLoading,
    isLoadingPreview,
  };
};
